<template>
  <div class="top-bar">
    <div class="top-left">
      <img src="../assets/logo.png" width="24px" />
      <div class="name">安司源id解析服务器</div>
    </div>
    <div class="top-right">
      <!-- <img
        src="../assets/topmenu.png"
        style="width:1.71rem"
        @click.stop="handleShowTopMenu()"
      />
      <div :class="{ active: showTopMenu, none: !showTopMenu }">
        <a
          href="https://b.akey.me/app/mallSelfDeploy"
          target="_blank"
          style="color: #333333;"
          ><div class="menu-item">购买密信盒子</div></a>
        <div class="divide"></div>
        <a
            href="https://app.boxtalk.net/"
            target="_blank"
            style="color:#333333"
            ><div class="menu-item">下载 BoxTalk App</div></a>
        <div class="divide"></div>
        <a href="tel: 400-921-9605"><div class="menu-item">联系我们</div></a>
      </div> -->
      <a href="tel: 400-823-0608" style="color: white;"><div>联系我们</div></a>
      <a href="https://b.akey.me/app/liveqr"
          target="_blank"
          style="color: white;margin-left:1.5rem"><div>购买</div></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileTopBar',
  data () {
    return {
      showTopMenu: false
    }
  },
  methods: {
    handleShowTopMenu: function () {
      this.showTopMenu = !this.showTopMenu
    }
  },
  mounted () {
    document.onclick = () => {
      if (this.showTopMenu) {
        this.showTopMenu = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.top-bar {
  height: 3rem;
  padding: 0 6vw;
  background: #4ea2fc;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.name {
  text-align: left;
  margin-left: 0.71rem;
  width: 11rem;
  height: 1.57rem;
  font-size: 1.14rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 1.57rem;
}
.top-right {
  // position: relative;
  // display: inline-block;
  display: flex;
  align-items: center;
}
.none {
  display: none;
}
.active {
  position: absolute;
  width: 12rem;
  background: #f8f8f9;
  border-radius: 6px;
  box-shadow: 0rem 0.29rem 0.71rem 0rem rgba(11, 67, 128, 0.57);
  right: 0;
  top: 2.5rem;
  padding: 0.3rem 0rem;
}
.divide {
  height: 0.07rem;
  background: #4ea2fc;
  opacity: 0.2;
}
.menu-item {
  text-align: left;
  height: 3rem;
  font-size: 1rem;
  color: #333333;
  line-height: 3rem;
  padding: 0rem 1.5rem;
}
.phone {
  width: 11.36rem;
  height: 1.43rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.43rem;
  margin-right: 2.14rem;
}
.buy-button {
  cursor: pointer;
  width: 5.71rem;
  height: 2.33rem;
  background: #ffffff;
  border-radius: 0.29rem;
  line-height: 2.33rem;
  font-size: 1rem;
  color: #4ea2fc;
}
a {
  text-decoration: none;
}
</style>
