<template>
  <div class="mobile-index">
    <div class="header">
      <MobileTopBar />
    </div>
    <div class="banner">
      <div class="download-area">
        <div class="download-des">密信盒子，看得见的安全</div>
        <div class="download-name">使用 BoxTalk App 加入盒子</div>
        <div class="download">
          <a
            href="https://app.boxtalk.net/"
            target="_blank"
            style="color:#4EA2FC"
            >下载</a
          >
        </div>
      </div>
    </div>
    <div class="problem-area">
      <div class="problems">
        <div class="problem-item">
          <div class="problem-text">
            担心公司重要文件泄漏？
          </div>
          <img src="../assets/mpro1.png" style="width:6.5rem" />
        </div>
        <div class="problem-item">
          <div class="problem-text">
            内部机密聊天被截图外传？
          </div>
          <img src="../assets/mpro2.png" style="width:6.5rem" />
        </div>
        <div class="problem-item">
          <div class="problem-text">
            小圈子悄悄话，担心被窃听？
          </div>
          <img src="../assets/mpro3.png" style="width:6.5rem" />
        </div>
      </div>
      <img src="../assets/mto.png" style="margin-top:0.7rem;" />
      <div class="line-text">一个盒子开启您的专属加密聊天</div>
      <div>
        <img
          src="../assets/mbox.png"
          style="margin-bottom:1.5rem;width:21.43rem"
        />
      </div>
    </div>
    <div class="product-area" style="background: #24284308;">
      <div class="product-title">产品介绍</div>
      <div class="video">
        <video
          :src="videoUrl"
          :poster="videoImgUrl"
          type="video/mp4"
          controls
          style="height: 13rem;"
        />
      </div>
    </div>
    <div class="product-area">
      <div class="product-title">产品特点</div>
      <div class="product-main">
        <div class="product-text">
          <div class="title-area">看的见的安全</div>
          <div class="des-area">
            聊天消息只经过密信盒子，中间不解密。数据加密存储在您的盒子里，谁也拿不走。加密技术由国家密码局权威认证
          </div>
        </div>
        <img src="../assets/minset1.png" style="width:21.43rem" />
      </div>
      <div class="product-main">
        <div class="product-text">
          <div class="title-area">即插即用，非常方便</div>
          <div class="des-area">
            连上电源，插上网线，立即拥有自己的加密聊天服务器
          </div>
        </div>
        <img src="../assets/minset2.png" style="width:21.43rem" />
      </div>
      <div class="product-main">
        <div class="product-text">
          <div class="title-area">极致轻薄，可随身携带，随时使用</div>
          <div class="des-area">
            随身携带如何做到可靠聊天?官方提供 BoxNet
            内网穿透辅助网络，不用固定IP，随时随地，只要盒子联网就能开始聊天
          </div>
        </div>
        <img src="../assets/minset3.png" style="width:21.43rem" />
      </div>
    </div>
    <div class="protect">
      <div class="product-text" style="margin:1.43rem 0rem;">
        <div class="title-area">信息高保护</div>
        <div style="margin:0.4rem 0rem">
          <div class="protect-des1">远程销毁</div>
          <div class="protect-des2">
            发出去的消息随时可以销毁，没有限制，覆水不再难收
          </div>
        </div>
        <div style="margin:0.4rem 0rem">
          <div class="protect-des1">绝密会话</div>
          <div class="protect-des2">一次性聊天，边聊边删，不留痕迹</div>
        </div>
        <div style="margin:0.4rem 0rem">
          <div class="protect-des1">No!Shot特效</div>
          <div class="protect-des2">动效打码，有效防止消息被完整截屏</div>
        </div>
      </div>
    </div>
    <div class="buy-area">
      <div style="width: 100%;height: 14.29rem;background: #EDF4FD;line-height:14.29rem">
        <img src="../assets/mbuy.png" style="width:26.79rem;" />
      </div>
      <div class="buy-main">
        <div class="base-info">
          <div style="display:flex;flex-direction: column;align-items:flex-start">
            <div class="base">密信盒子基础版</div>
            <div class="license">赠送10人永久授权</div>
          </div>
          <div class="price">¥2599</div>
        </div>
        <div class="buy-info" style="justify-content: space-between;">
          <div class="buy-info-title">规格：</div>
          <div class="buy-info-text">
            <div>4核CPU</div>
            <div style="margin:0rem 1.43rem">8G内存</div>
            <div>256G SSD硬盘</div>
          </div>
        </div>
        <div
          class="buy-info"
          style="flex-direction: column;align-items:flex-start"
        >
          <div class="buy-info-title" style="margin-bottom:0.6rem">
            其他授权人数：
          </div>
          <div class="buy-info-text">
            <div style="margin-right:3rem">25人永久授权</div>
            <div>另加 ¥18000</div>
          </div>
          <div class="buy-info-text" style="margin:0.15rem 0rem">
            <div style="margin-right:3rem">50人永久授权</div>
            <div>另加 ¥35000</div>
          </div>
        </div>

        <a
          href="https://b.akey.me/app/liveqr"
          target="_blank"
          style="color:white"
          ><div class="buy-button">购买</div>
        </a>
      </div>
    </div>
    <div class="footer">
        <MobileFooterBar />
    </div>
  </div>
</template>

<script>
import MobileTopBar from '@/components/MobileTopBar.vue'
import MobileFooterBar from '@/components/MobileFooterBar.vue'
import { videoUrl, videoImgUrl } from '@/common/constant.js'
export default {
  name: 'MobileIndex',
  components: {
    MobileTopBar,
    MobileFooterBar
  },
  data () {
    return {
      videoUrl,
      videoImgUrl
    }
  }
}
</script>

<style lang="less" scoped>
.banner {
  background: url(../assets/mbg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 66vw;
  color: white;
}
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.download-area {
  padding-top: 21vw;
  padding-left: 6vw;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.download-name {
  width: 14.57rem;
  height: 1.43rem;
  font-size: 1rem;
  color: #ffffff;
  line-height: 1.43rem;
}
.download-des {
  width: 20rem;
  height: 2rem;
  font-size: 1.3rem;
  color: #ffffff;
  line-height: 2rem;
}
.download {
  cursor: pointer;
  line-height: 2.86rem;
  text-align: center;
  margin-top: 1.14rem;
  background: #ffffff;
  border-radius: 0.29rem;
  color: #4ea2fc;
  width: 4.64rem;
  font-size: 1rem;
  line-height: 2rem;
  width: 5.43rem;
  height: 2rem;
}
a {
  text-decoration: none;
}
.problems {
  margin-top: 1.15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.problem-item {
  text-align: left;
  width: 24.29rem;
  height: 3.57rem;
  background: #fef7e7;
  display: flex;
  margin: 0.35rem;
}
.problem-text {
  width: 10.71rem;
  font-size: 1.14rem;
  line-height: 3.57rem;
  width: 17.79rem;
  height: 3.57rem;
  color: #f7ba12;
  margin-left: 1.43rem;
}
.line-text {
  height: 2rem;
  font-size: 1.43rem;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #333333;
  line-height: 2rem;
  margin: 1.29rem 0rem 2rem;
}
.product-area {
  text-align: center;
  padding: 1.43rem;
}
.product-title {
  margin: 0 auto 1.43rem auto;
  background: #dae6fb;
  width: 5.71rem;
  height: 1.3rem;
  font-size: 1.29rem;
  color: #333333;
  line-height: 1.29rem;
  background: linear-gradient(
    to bottom,
    #00000000 0%,
    #00000000 50%,
    #e1efff 51%,
    #e1efff 100%
  );
}
.product-main {
  padding: 2.14rem 0rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.product-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title-area {
  height: 1.57rem;
  font-size: 1.14rem;
  color: #333333;
  line-height: 1.57rem;
  margin-bottom: 0.71rem;
}
.des-area {
  width: 24.93rem;
  height: 4.29rem;
  font-size: 1rem;
  color: #999999;
  line-height: 1.43rem;
  margin-bottom: 0.71rem;
}
.protect {
  background: #24284308;
  display: flex;
  justify-content: center;
  align-items: center;
}
.protect-des1 {
  line-height: 1.43rem;
  font-size: 1rem;
}
.protect-des2 {
  line-height: 1.43rem;
  color: #999999;
  font-size: 1rem;
}
.buy-area {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.43rem 0rem 2.29rem;
  flex-direction: column;
}
.buy-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0.86rem 1.43rem;
}
.base-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.license {
  width: 8.86rem;
  height: 1.71rem;
  background: #f8873c;
  border-radius: 0.14rem;
  font-size: 1rem;
  color: #ffffff;
  line-height: 1.72rem;
  margin-bottom: 1rem;
}
.base {
  height: 1.57rem;
  font-size: 1.14rem;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #333333;
  line-height: 1.57rem;
  margin-bottom: 0.14rem;
}
.price {
  width: 4.86rem;
  height: 2.21rem;
  font-size: 1.86rem;
  color: #f44c4c;
  line-height: 2.21rem;
  font-weight: 600;
}
.buy-info {
  margin: 0.8rem 0rem;
  display: flex;
}
.buy-info-title {
  height: 1.43rem;
  font-size: 1rem;
  color: #999999;
  line-height: 1.43rem;
}
.buy-info-text {
  display: flex;
  height: 1.43rem;
  font-size: 1rem;
  color: #333333;
  line-height: 1.43rem;
}
.buy-button {
  width: 24.29rem;
  height: 3.14rem;
  background: #4ea2fc;
  border-radius: 0.57rem;
  font-size: 1.14rem;
  color: #ffffff;
  line-height: 3.14rem;
  margin: 1.43rem 0rem;
}
.buy-inner {
  height: 2.57rem;
  font-size: 1.79rem;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #ffffff;
}
</style>
