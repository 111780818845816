<template>
  <div class="footerBar">
    <div id="copy-right">版权所有杭州安司源科技有限公司<a href="https://beian.miit.gov.cn/" target="_blank" style="color:rgba(0,0,0,1);"> 浙ICP备15007747号-3 </a></div>
  </div>
</template>
<script>
export default {
  name: 'MobileFooterBar'
}
</script>
<style lang="less" scoped>
.footerBar {
  background: #24284308;
  height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#copy-right{
  font-size: 1rem;
  color:rgba(0,0,0,1);
  line-height: 1.43rem;
}
a {
  text-decoration:none;
}
</style>
